import { useContext } from 'react'
import { CompaniesContext, ReleasedClient } from '../../../contexts/CompaniesContext'
import { TableContext } from '../../../contexts/TableContext'
import { AuthContext } from '../../../contexts/AuthContext'

import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Cookies from 'js-cookie'

import close from '../../../assets/icons/close.svg'

import './styles.css'

interface CompaniesModalProps {
  modalCompaniesOpen: boolean
  setModalCompaniesOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Companies({ modalCompaniesOpen, setModalCompaniesOpen }: CompaniesModalProps) {
  const isClient = Cookies.get('__IS_CLIENT')

  const { releasedClients, setSelectedSerie, selectedClient, setSelectedClient, handleClientSelection } =
    useContext(CompaniesContext)
  const { handleFilterChange, setDocumentsInContext } = useContext(TableContext)
  const { logout } = useContext(AuthContext)

  return (
    <Modal
      open={modalCompaniesOpen}
      onClose={() => {
        if (Object.keys(selectedClient).length) setModalCompaniesOpen(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal__box">
        <div className="modal__header">
          <h1>{isClient ? 'SUAS EMPRESAS' : 'SEUS CLIENTES'}</h1>

          <img
            src={close}
            alt="close"
            onClick={() => {
              if (Object.keys(selectedClient).length) setModalCompaniesOpen(false)
            }}
          />
        </div>

        <div className="modal__body">
          {releasedClients.length ? (
            releasedClients.map((client) => (
              <div className="acc_client" key={client.id}>
                <h3>{client.razao_social}</h3>

                <button
                  onClick={() => {
                    setSelectedClient({} as ReleasedClient)
                    setSelectedSerie(0)
                    handleFilterChange({ key: 'client', value: client.id })
                    window.localStorage.removeItem('__CLIENT')
                    handleClientSelection(client.id)
                    setModalCompaniesOpen(false)
                    setDocumentsInContext(null)
                    window.location.pathname !== '/dashboard' && window.history.back()
                  }}
                >
                  selecionar
                </button>
              </div>
            ))
          ) : (
            <div className="no__clients">
              <h1>
                infelizmente você ainda não possui nenhum cliente liberado, entre em contato com o seu cliente e
                solicite o link de liberação.
              </h1>

              <a href="/" onClick={() => logout()}>
                sair
              </a>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  )
}
