import { useEffect, useState } from 'react'
import { Avatar } from '@mui/material'
import { Person } from '@mui/icons-material'
import Cookies from 'js-cookie'

import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

import close from '../../../assets/icons/close.svg'

import ChangeUsername from './ChangeUsername'
import ChangePassword from './ChangePassword'

import './styles.css'

export interface DownloadRequestsProps {
  id: number
  socket: string
  url: string
  tipo_documento: number
  arquivos: string
  data_cadastro: string
}

interface DownloadRequestsModalProps {
  modalProfileOpen: boolean
  setModalProfileOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Profile({
  modalProfileOpen,
  setModalProfileOpen,
}: DownloadRequestsModalProps) {
  const userId = Cookies.get('__ID')
  const username = Cookies.get('__CONTAB')

  const [modalChangeUsernameOpen, setModalChangeUsernameOpen] = useState(false)
  const [modalChangePasswordOpen, setModalChangePasswordOpen] = useState(false)

  return (
    <Modal
      open={modalProfileOpen}
      onClose={() => setModalProfileOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal__profile">
        <div className="modal__header">
          <h1>PERFIL</h1>

          <img src={close} alt="close" onClick={() => setModalProfileOpen(false)} />
        </div>

        <div className="modal__body">
          <div className="user__details">
            <Avatar sx={{ width: 92, height: 92 }}>
              <Person style={{ fontSize: 64 }} color="action" />
            </Avatar>

            <h1>{username}</h1>
          </div>

          <div className="actions">
            <h5 onClick={() => setModalChangeUsernameOpen(true)}>alterar razão social</h5>
            <h5 onClick={() => setModalChangePasswordOpen(true)}>alterar senha</h5>
          </div>
        </div>

        <ChangeUsername
          modalChangeUsernameOpen={modalChangeUsernameOpen}
          setModalChangeUsernameOpen={setModalChangeUsernameOpen}
        />

        <ChangePassword
          modalChangePasswordOpen={modalChangePasswordOpen}
          setModalChangePasswordOpen={setModalChangePasswordOpen}
        />
      </Box>
    </Modal>
  )
}
