import { useState } from 'react'
import { apiAuth } from '../../services/apiAuth'

import contabilidadeImage from '../../assets/images/contabilidade.png'

import emailIcon from '../../assets/icons/email.svg'
import arrowLeftIcon from '../../assets/icons/arrow-left.svg'
import Toast from '../../components/Toast'

import './styles.css'

export default function ForgotPassword() {
  const [email, setEmail] = useState('')

  const [waitingRequest, setWaitingRequest] = useState(false)

  const [toastType, setToastType] = useState<'error' | 'alert'>('alert')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('')

  function handleSubmit(e: any) {
    e.preventDefault()
    setWaitingRequest(true)
    sendMailToRecoverPassword()
  }

  async function sendMailToRecoverPassword() {
    await apiAuth
      .post('/usuarios/recuperar_senha', { email })
      .then(() => {
        setWaitingRequest(false)
        window.location.href = '/email_sended'
      })
      .catch((uncaught) => {
        setWaitingRequest(false)
        setToastType('error')
        setToastMessage(uncaught.data.message)
        setToastVisible(true)
        setTimeout(() => {
          setToastVisible(false)
        }, 5000)
      })
  }

  return (
    <div className="forgot__password">
      <form onSubmit={(e) => handleSubmit(e)}>
        <img src={contabilidadeImage} alt="silo-virtual" className="logo__app" />

        <h1>INFORME O E-MAIL CADASTRADO</h1>

        <div className="input__box">
          <input type="email" name="email" required placeholder="E-MAIL" onChange={(e) => setEmail(e.target.value)} />
          <img src={emailIcon} alt="" />
        </div>

        <Toast visible={toastVisible} type={toastType} title={toastMessage} />

        <button disabled={waitingRequest} className={waitingRequest ? 'button__loading' : ''} type="submit">
          <span>RECUPERAR SENHA</span>
        </button>

        <a href="/">
          <img src={arrowLeftIcon} alt="arrow-left" />
          VOLTAR PARA O LOGIN
        </a>
      </form>
    </div>
  )
}
