import { useState } from 'react'
import { Link } from 'react-router-dom'
import { apiAuth } from '../../services/apiAuth'

import Toast from '../../components/Toast'

import contabilidadeImage from '../../assets/images/contabilidade.png'
import emailIcon from '../../assets/icons/email.svg'
import lockIcon from '../../assets/icons/lock.svg'
import infoIcon from '../../assets/icons/info-rounded.svg'
import userIcon from '../../assets/icons/user.svg'

import ArrowBack from '@mui/icons-material/ArrowBack'

import './styles.css'

interface SubmitType {
  RAZAO_SOCIAL: string
  CRC: string
  EMAIL: string
  SENHA: string
  CONFIRMACAO_SENHA?: string
}

interface InputType {
  value: string
  name: string
}

export default function Signup() {
  const [waitingRequest, setWaitingRequest] = useState(false)
  const [formValues, setFormValues] = useState({} as SubmitType)
  const [toastType, setToastType] = useState<'error' | 'alert'>('error')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('')

  function handleInputChange(input: InputType) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [input.name]: input.value,
    }))
  }

  function handleSubmit(e: any) {
    e.preventDefault()
    createUser()
  }

  async function createUser() {
    setWaitingRequest(true)

    if (!(formValues.SENHA === formValues.CONFIRMACAO_SENHA)) {
      setToastType('error')
      setToastMessage('SENHAS DIFERENTES')
      setToastVisible(true)
      setTimeout(() => {
        setToastVisible(false)
      }, 5000)
      setWaitingRequest(false)
      return
    }

    await apiAuth
      .post('/usuarios', {
        razao_social: formValues.RAZAO_SOCIAL,
        email: formValues.EMAIL,
        senha: formValues.SENHA,
        crc: formValues.CRC,
      })
      .then((response) => {
        window.location.href = '/account_created'
      })
      .catch((uncaught) => {
        const { error } = uncaught.response.data

        if (error) {
          setToastType('error')
          setToastMessage(error)
          setToastVisible(true)
          setTimeout(() => {
            setToastVisible(false)
          }, 5000)
          setWaitingRequest(false)
          return
        }
      })
      .finally(() => setWaitingRequest(false))
  }

  return (
    <div className="signup">
      <div className="texts sm-screen">
        <img src={contabilidadeImage} alt="" />

        <h4>tenha acesso aos documentos fiscais dos seus clientes e agilize seus processos.</h4>

        <Link to="/">
          <ArrowBack fontSize="small" />
          <span>JÁ TENHO UMA CONTA</span>
        </Link>
      </div>

      <div className="form">
        <div className="form__box">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="input__box">
              <input
                type="text"
                required
                placeholder="NOME DA CONTABILIDADE"
                onChange={(e) =>
                  handleInputChange({
                    name: 'RAZAO_SOCIAL',
                    value: e.target.value,
                  })
                }
              />
              <img src={infoIcon} alt="enterprise-name" />
            </div>

            <div className="input__box">
              <input
                type="text"
                required
                placeholder="CRC"
                onChange={(e) =>
                  handleInputChange({
                    name: 'CRC',
                    value: e.target.value,
                  })
                }
              />
              <img src={userIcon} alt="crc" />
            </div>

            <div className="input__box">
              <input
                type="email"
                required
                placeholder="E-MAIL"
                onChange={(e) =>
                  handleInputChange({
                    name: 'EMAIL',
                    value: e.target.value,
                  })
                }
              />
              <img src={emailIcon} alt="email" />
            </div>

            <div className="input__box">
              <input
                type="password"
                required
                placeholder="SENHA"
                onChange={(e) =>
                  handleInputChange({
                    name: 'SENHA',
                    value: e.target.value,
                  })
                }
              />
              <img src={lockIcon} alt="password" />
            </div>

            <div className="input__box">
              <input
                type="password"
                required
                placeholder="CONFIRMAR SENHA"
                onChange={(e) =>
                  handleInputChange({
                    name: 'CONFIRMACAO_SENHA',
                    value: e.target.value,
                  })
                }
              />
              <img src={lockIcon} alt="password-confirmation" />
            </div>

            <Toast visible={toastVisible} type={toastType} title={toastMessage} />

            <button
              type="submit"
              disabled={waitingRequest}
              className={waitingRequest ? 'button__loading' : ''}
            >
              <span>CRIAR CONTA</span>
            </button>
          </form>
        </div>
      </div>

      <div className="texts md-screen">
        <img src={contabilidadeImage} alt="" />

        <h4>tenha acesso aos documentos fiscais dos seus clientes e agilize seus processos.</h4>

        <Link to="/">
          <ArrowBack fontSize="small" />
          <span>JÁ TENHO UMA CONTA</span>
        </Link>
      </div>
    </div>
  )
}
