import { FormEvent, useEffect, useState } from 'react'

import Cookies from 'js-cookie'

import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

import close from '../../../../assets/icons/close.svg'

import { apiAuth } from '../../../../services/apiAuth'

import './styles.css'
import Toast from '../../../Toast'
import { AxiosError } from 'axios'

export interface DownloadRequestsProps {
  id: number
  socket: string
  url: string
  tipo_documento: number
  arquivos: string
  data_cadastro: string
}

interface DownloadRequestsModalProps {
  modalChangePasswordOpen: boolean
  setModalChangePasswordOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ChangePassword({
  modalChangePasswordOpen,
  setModalChangePasswordOpen,
}: DownloadRequestsModalProps) {
  const userId = Cookies.get('__ID')
  const username = Cookies.get('__CONTAB')

  const [toastType, setToastType] = useState<'error' | 'alert'>('error')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('SENHAS DIFERENTES')

  const [waitingRequest, setWaitingRequest] = useState(false)

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  function validatePasswords(): boolean {
    if (newPassword !== confirmPassword) {
      setToastType('error')
      setToastMessage('SENHAS DIFERENTES')
      setToastVisible(true)
      setTimeout(() => {
        setToastVisible(false)
      }, 5000)
      setWaitingRequest(false)

      return false
    }

    return true
  }

  async function changePassword(e: FormEvent) {
    e.preventDefault()

    setWaitingRequest(true)

    const samePasswords = validatePasswords()

    if (samePasswords) {
      try {
        await apiAuth.patch(`/usuarios/${userId}/senha`, {
          senha_atual: oldPassword,
          nova_senha: newPassword,
        })

        setToastType('alert')
        setToastMessage('Senha alterada')
        setToastVisible(true)
        setTimeout(() => {
          setToastVisible(false)
          setModalChangePasswordOpen(false)
        }, 3000)
        setWaitingRequest(false)
      } catch (err: any) {
        setToastType('error')
        setToastMessage(err.response.data.error)
        setToastVisible(true)
        setTimeout(() => {
          setToastVisible(false)
        }, 5000)
        setWaitingRequest(false)
      }
    }
  }

  return (
    <Modal
      open={modalChangePasswordOpen}
      onClose={() => setModalChangePasswordOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal__change__password">
        <div className="modal__header">
          <h1>ALTERAR SENHA</h1>

          <img src={close} alt="close" onClick={() => setModalChangePasswordOpen(false)} />
        </div>

        <div className="modal__body">
          <form onSubmit={(e) => changePassword(e)}>
            <input
              required
              type="password"
              placeholder="senha atual"
              autoFocus
              onChange={(e) => setOldPassword(e.target.value)}
            />

            <input
              required
              type="password"
              placeholder="nova senha"
              onChange={(e) => setNewPassword(e.target.value)}
            />

            <input
              required
              type="password"
              placeholder="confirmar senha"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <Toast visible={toastVisible} type={toastType} title={toastMessage} />

            <div className="buttons">
              <button
                disabled={waitingRequest}
                className={`confirm ${waitingRequest && 'button__loading'}`}
                type="submit"
              >
                <span>CONFIRMAR</span>
              </button>
              <button className="cancel" onClick={() => setModalChangePasswordOpen(false)}>
                <span>CANCELAR</span>
              </button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  )
}
