import ReactDOM from 'react-dom/client'
import Router from './router'
import ReactGA from 'react-ga'

import './index.css'

import { TableProvider } from './contexts/TableContext'
import { AuthProvider } from './contexts/AuthContext'
import { CompaniesProvider } from './contexts/CompaniesContext'

// const GA_TRACKING_ID = 'G-GPXCYN6FR2'
// ReactGA.initialize(GA_TRACKING_ID)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <AuthProvider>
    <TableProvider>
      <CompaniesProvider>
        <Router />
      </CompaniesProvider>
    </TableProvider>
  </AuthProvider>
)
