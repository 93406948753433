import { useState } from 'react'

import contabHorizontal from '../../assets/images/contab-horizontal.png'
import iconeLeaf from '../../assets/images/leaf-white.png'

import AccountMenu from '../AccountMenu'

import './styles.css'

export default function Header() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const accountMenuOpen = Boolean(anchorEl)

  return (
    <header>
      <div className="container">
        <img src={contabHorizontal} alt="contab-branca" className="md-logo" />
        <img src={iconeLeaf} alt="contab-icone-branco" className="sm-logo" />

        <div className="buttons">
          <AccountMenu
            accountMenuOpen={accountMenuOpen}
            setAnchorEl={setAnchorEl}
            anchorEl={anchorEl}
          />
        </div>
      </div>
    </header>
  )
}
