import * as React from 'react'

import arrows from '../../assets/icons/arrows.svg'

import { CompaniesContext } from '../../contexts/CompaniesContext'
import { TableContext } from '../../contexts/TableContext'

import MenuUnstyled from '@mui/base/MenuUnstyled'
import MenuItemUnstyled from '@mui/base/MenuItemUnstyled'
import PopperUnstyled from '@mui/base/PopperUnstyled'

import styled from '@emotion/styled'

import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft'

import './styles.css'

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: var(--font-mono-regular);
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 200px;
  background: var(--dark-gray);
  border-radius: 0.25em;
  overflow: auto;
  outline: 0px;
  width: 200px;
  `
)

const StyledMenuItem = styled(MenuItemUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  font-weight: bold;
  cursor: default;
  user-select: none;
  color: #fff;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: var(--light-gray);
  }
  `
)

const Popper = styled(PopperUnstyled)`
  z-index: 1;
`

export default function SubHeader() {
  const { selectedClient, selectedSerie, setSelectedSerie } = React.useContext(CompaniesContext)
  const { handleFilterChange } = React.useContext(TableContext)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const pathname = window.location.pathname

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleSerieChange(serie: number) {
    setSelectedSerie(serie)
    handleFilterChange({ key: 'series', value: serie })
    handleClose()
  }

  return (
    <div className="client">
      {pathname !== '/dashboard' && (
        <div className="back__menu" onClick={() => window.history.back()}>
          <ArrowLeft />
          <span>voltar para home</span>
        </div>
      )}

      <h1>{selectedClient.razao_social}</h1>
      <div className="title">
        <h5>série: {selectedSerie}</h5>
        <button onClick={(e) => handleClick(e)}>
          <img
            src={arrows}
            alt="arrows"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          />
        </button>

        <MenuUnstyled
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          components={{ Root: Popper, Listbox: StyledListbox }}
        >
          {selectedClient.series?.map((serie, index) => (
            <StyledMenuItem key={index} onClick={() => handleSerieChange(Number(serie))}>
              {serie}
            </StyledMenuItem>
          ))}
        </MenuUnstyled>
      </div>
    </div>
  )
}
