import { createContext, useEffect } from 'react'
import { useState } from 'react'
import { apiAuth } from '../services/apiAuth'

import Cookies from 'js-cookie'

interface AuthPayloadType {
  id: number
  verificado: boolean
  configurado: boolean
  empresa: string
  token: string
}

interface AuthContextType {
  isAuthenticated: boolean
  signIn: (data: SignInData) => Promise<null | false | AuthPayloadType>
  logout: () => Promise<void>
  invite: string | null
}

interface SignInData {
  user: string
  password: string
}

export const AuthContext = createContext({} as AuthContextType)

export function AuthProvider({ children }: any) {
  const unrequiredTokenPages = [
    '/',
    '/signup',
    '/signup/confirm',
    '/forgot_password',
    '/account_created',
    '/recover_password',
    '/recovered_password',
    '/email_sended',
    '/unverified',
  ]
  const [token, setToken] = useState('')

  const isAuthenticated = !!token

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const invite = urlParams.get('invite')

  useEffect(() => {
    const tokenAlreadyExists = Cookies.get('__TOKEN')

    if (invite) {
      localStorage.setItem('invite', invite)
    }

    if (tokenAlreadyExists) {
      verifyToken(tokenAlreadyExists)
    } else {
      const pathname = window.location.pathname
      if (!unrequiredTokenPages.includes(pathname)) {
        window.location.href = '/'
      }
    }
  }, [isAuthenticated])

  async function verifyToken(token: string) {
    const tokenIsValid = await apiAuth
      .get(`/usuarios/verificar_token/${token}`)
      .then((response) => {
        return response.data.valid
      })
      .catch((err) => {
        return null
      })

    if (!tokenIsValid) {
      Cookies.remove('__TOKEN')
      window.location.href = '/'
    } else {
      if (window.location.pathname === '/') {
        window.location.href = '/dashboard'
      }
    }
  }

  async function signIn({ user, password }: SignInData) {
    const returnSignIn = await apiAuth
      .post('/usuarios/auth', {
        usuario: user,
        senha: password,
      })
      .then((response) => {
        return response.data
      })
      .catch((uncaught) => {
        if (uncaught.response.status === 401) {
          return false
        }

        return null
      })

    if (returnSignIn === null) {
      return null
    } else if (returnSignIn === false) {
      return false
    }

    Cookies.set('__TOKEN', returnSignIn.token, { expires: 1 })
    Cookies.set('@CONTAB:email', user)
    Cookies.set('__ID', returnSignIn.id.toString())
    Cookies.set('__CONTAB', returnSignIn.contab)
    Cookies.set('__IS_CLIENT', returnSignIn.cliente)
    setToken(returnSignIn.token)

    return returnSignIn
  }

  async function logout() {
    window.localStorage.clear()
    Cookies.remove('__TOKEN')
    setToken('')
    window.location.href = '/'
  }

  return <AuthContext.Provider value={{ isAuthenticated, signIn, logout, invite }}>{children}</AuthContext.Provider>
}
