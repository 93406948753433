import { useState, createContext, Dispatch, SetStateAction, useEffect } from 'react'
import { api } from '../services/api'
import { error } from '../utils/browser-loggers'
import { matchFilesType } from '../utils/match-files-type'

interface TableContextType {
  documentType: string
  allChecked: boolean
  documents: DocumentProps[] | null
  documentsInContext: DocumentProps[] | null
  selectedItems: DocumentProps[]
  filterProps: FilterProps
  setDocumentsInContext: Dispatch<SetStateAction<DocumentProps[] | null>>
  setDocuments: Dispatch<SetStateAction<DocumentProps[] | null>>
  selectedYear: string
  setSelectedYear: Dispatch<SetStateAction<string>>
  clearSelectedItems: () => void
  handleFilterChange: (filterField: { key: string; value: string | number | undefined }) => void
  handleDocumentTypeSelection: (type: string) => void
  handleItemSelection: (item: DocumentProps) => void
  handleCheck: () => void
  handleBasicSearch: () => void
}

export interface DocumentProps {
  id: number
  chave: string
  data_emissao: string
  cfop: string
  serie: string
  valor: number
  numero: number
  s3_pdf: string
  s3_xml: string
  s3_pdf_nome_arquivo?: string
  s3_xml_nome_arquivo?: string
  tipo: number
}

export interface FilterProps {
  initialDate?: string | undefined
  finalDate?: string | undefined
  cfop?: string | undefined
  documentNumber?: string | undefined
  key?: string | undefined
  series?: number
  client?: number
  type?: number
}

export const TableContext = createContext({} as TableContextType)

export function TableProvider({ children }: any) {
  const [documentType, setDocumentType] = useState('nfe')
  const [documentsInContext, setDocumentsInContext] = useState<DocumentProps[] | null>(null)
  const [selectedItems, setSelectedItems] = useState<DocumentProps[]>([])
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString())
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [filterProps, setFilterProps] = useState<FilterProps>({})
  const [documents, setDocuments] = useState<DocumentProps[] | null>(null)

  useEffect(() => {
    const date = new Date()

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const todayInDateString = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`
    const firstDayOfMonth = `${year}-${String(month).padStart(2, '0')}-01`

    setFilterProps({
      initialDate: firstDayOfMonth,
      finalDate: todayInDateString,
      type: matchFilesType('nfe'),
    })
  }, [])

  function handleCheck() {
    if (!allChecked === true) {
      setSelectedItems(documents ? documents : [])
    } else {
      setSelectedItems([])
    }

    setAllChecked(!allChecked)
  }

  function clearSelectedItems() {
    setAllChecked(false)
    setSelectedItems([])
  }

  function handleItemSelection(item: DocumentProps) {
    const itemAlreadyExists = selectedItems.findIndex((arrItem) => arrItem.id === item.id)

    if (itemAlreadyExists !== -1) {
      let oldArr = selectedItems
      oldArr.splice(itemAlreadyExists, 1)
      setSelectedItems(oldArr)
      return
    }

    setSelectedItems((prevItems) => [...prevItems, item])
  }

  function handleDocumentTypeSelection(type: string) {
    setDocumentsInContext(null)
    setDocumentType(type)
    handleFilterChange({ key: 'type', value: matchFilesType(type) })
    clearSelectedItems()
  }

  function handleBasicSearch() {
    let queryParams: string = '?'

    const possibleParamsKeys = Object.keys(filterProps)
    const possibleParamsValues = Object.values(filterProps)

    for (let i = 0; i < possibleParamsKeys.length; i++) {
      if (possibleParamsValues[i]) {
        queryParams = `${queryParams}${possibleParamsKeys[i]}=${possibleParamsValues[i]}&`
      }
    }

    api
      .get(`/arquivos/filtrar${queryParams}`)
      .then((response) => {
        setDocumentsInContext(response.data)
      })
      .catch((err) => error('fail on basic search', { err }))
  }

  function handleFilterChange(filterField: { key: string; value: string | number | undefined }) {
    setFilterProps((prevState) => ({ ...prevState, [filterField.key]: filterField.value }))
  }

  return (
    <TableContext.Provider
      value={{
        allChecked,
        selectedItems,
        handleItemSelection,
        clearSelectedItems,
        handleCheck,
        documentsInContext,
        setDocuments,
        documents,
        setDocumentsInContext,
        selectedYear,
        setSelectedYear,
        documentType,
        handleDocumentTypeSelection,
        filterProps,
        handleFilterChange,
        handleBasicSearch,
      }}
    >
      {children}
    </TableContext.Provider>
  )
}
