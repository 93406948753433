import { BrowserRouter, Routes, Route } from 'react-router-dom'
import openSocket from 'socket.io-client'
import ReactGA from 'react-ga'

import Home from './pages/home'
import Login from './pages/login'
import Docs from './pages/docs'
import Signup from './pages/signup'
import ConfirmedAccount from './pages/confirmed-account'
import AccountCreated from './pages/account-created'
import ForgotPassword from './pages/forgot-password'
import RecoverPassword from './pages/recover-password'
import RecoveredPassword from './pages/recovered-password'
import EmailSended from './pages/email-sended'
import Unverified from './pages/unverified'

import { variables } from './variables'

// const GA_TRACKING_ID = 'G-GPXCYN6FR2'
// ReactGA.initialize(GA_TRACKING_ID)

export default function Router() {
  const socket = openSocket(variables.SOCKET)

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search)
  // }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Home socket={socket} />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/confirm" element={<ConfirmedAccount />} />
        <Route path="/account_created" element={<AccountCreated />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/recover_password" element={<RecoverPassword />} />
        <Route path="/recovered_password" element={<RecoveredPassword />} />
        <Route path="/email_sended" element={<EmailSended />} />
        <Route path="/unverified" element={<Unverified />} />
        <Route path="/docs" element={<Docs socket={socket} />} />
      </Routes>
    </BrowserRouter>
  )
}
