import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Box } from '@mui/material'

import Modal from '@mui/material/Modal'

import close from '../../../assets/icons/close.svg'

import './styles.css'

export interface InviteInstructionsProps {
  id: number
  socket: string
  url: string
  tipo_documento: number
  arquivos: string
  data_cadastro: string
  contabilidade: string
}

interface InviteInstructionsModalProps {
  modalInviteInstructionsOpen: boolean
  setModalInviteInstructionsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function InviteInstructions({
  modalInviteInstructionsOpen,
  setModalInviteInstructionsOpen,
}: InviteInstructionsModalProps) {
  return (
    <Modal
      open={modalInviteInstructionsOpen}
      onClose={() => setModalInviteInstructionsOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal__box__requests">
        <div className="modal__header">
          <h1>INSTRUÇÕES</h1>

          <img src={close} alt="close" onClick={() => setModalInviteInstructionsOpen(false)} />
        </div>

        <div className="modal__body">
          <h3>Opa, percebemos que você foi convidado.</h3>
          <p>Aqui vai algumas instruções para os próximos passos:</p>
          <p>1. Faça o login ou cadastre-se.</p>
          <p>
            2. Acessando o link do convite recebido e acessando a sua conta você estará habilitado
            para visualizar os documentos da empresa que lhe convidou.
          </p>
          <p>
            3. Caso outra empresa lhe convide você poderá escolher a empresa a ser visualizada no
            momento em que acessar a plataforma.
          </p>
          <p>
            4. Dê uma olhada no nosso <a href="">vídeo</a> de apresentação da plataforma mostrando o
            que você pode fazer por aqui.
          </p>

          <div className="modal__footer">
            <span onClick={() => setModalInviteInstructionsOpen(false)}>
              <span>CONTINUAR</span>
              <ArrowForward fontSize="small" />
            </span>
          </div>
        </div>
      </Box>
    </Modal>
  )
}
