import { useContext, useEffect, useState } from 'react'
import { TableContext } from '../../contexts/TableContext'

import xmlIcon from '../../assets/icons/xmlIcon.svg'

import './styles.css'

interface TableRowProps {
  item: ItemProps
}

interface ItemProps {
  id: number
  chave: string
  data_emissao: string
  cfop: string
  serie: string
  valor: number
  numero: number
  s3_pdf: string
  s3_xml: string
  tipo: number
}

export default function TableRow({ item }: TableRowProps) {
  const { allChecked, handleItemSelection, selectedItems } = useContext(TableContext)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (allChecked === false) {
      setChecked(false)
    }
  }, [allChecked])

  function copyToClipboard(chave: string) {
    navigator.clipboard.writeText(chave)
  }

  return (
    <tr className="table__row">
      <td
        align="center"
        onClick={() => {
          setChecked(!checked)
          handleItemSelection(item)
        }}
      >
        <input
          type="checkbox"
          checked={selectedItems.filter((x) => x.id === item.id)[0] ? true : false}
          readOnly
        />
      </td>
      <td>{item.numero}</td>
      <td>{new Date(new Date(item.data_emissao).setHours(24)).toLocaleDateString('pt-BR')}</td>
      <td>
        <span title={item.cfop}>{item.cfop}</span>
      </td>
      <td align="right">
        {item.valor.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}
      </td>
      <td
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          cursor: 'pointer',
        }}
        title="COPIAR CHAVE"
        onClick={() => copyToClipboard(item.chave)}
      >
        <span title={item.chave}>{item.chave}</span>
      </td>
      <td className="row__icons">
        <div>
          <a href={item.s3_xml} target="_blank">
            <img src={xmlIcon} alt="xml-icon" title="Baixar XML" />
          </a>
        </div>
      </td>
    </tr>
  )
}
