import { Box } from '@mui/material'

import Modal from '@mui/material/Modal'

import close from '../../../assets/icons/close.svg'

import './styles.css'

interface HelpModalProps {
  modalHelpOpen: boolean
  setModalHelpOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Help({ modalHelpOpen, setModalHelpOpen }: HelpModalProps) {
  return (
    <Modal
      open={modalHelpOpen}
      onClose={() => setModalHelpOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal__box__requests">
        <div className="modal__header">
          <h1>AJUDA</h1>

          <img src={close} alt="close" onClick={() => setModalHelpOpen(false)} />
        </div>

        <div className="modal__body">
          <p>
            1. <a href="">Vídeo</a> de aprensentação de funcionalidades da plataforma.
          </p>
        </div>
      </Box>
    </Modal>
  )
}
