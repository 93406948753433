import * as React from 'react'

import { AuthContext } from '../../contexts/AuthContext'
import { Help } from '@mui/icons-material'

import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Person from '@mui/icons-material/Person'
import PersonPin from '@mui/icons-material/PersonPin'
import Logout from '@mui/icons-material/Logout'
import Download from '@mui/icons-material/Download'
import DownloadRequests from '../Modals/DownloadRequests'
import Profile from '../Modals/Profile'
import Companies from '../Modals/Companies'
import HelpModal from '../Modals/Help'

import Cookies from 'js-cookie'

interface AccountMenuModalProps {
  anchorEl: any
  setAnchorEl: any
  accountMenuOpen: any
}

export default function AccountMenu({ anchorEl, setAnchorEl, accountMenuOpen }: AccountMenuModalProps) {
  const [modalDownloadRequestsOpen, setModalDownloadRequestsOpen] = React.useState(false)
  const [modalProfileOpen, setModalProfileOpen] = React.useState(false)
  const [modalCompaniesOpen, setModalCompaniesOpen] = React.useState(false)
  const [modalHelpOpen, setModalHelpOpen] = React.useState(false)

  const { logout } = React.useContext(AuthContext)

  const isClient = Number(Cookies.get('__IS_CLIENT'))

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Detalhes da Conta">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={accountMenuOpen ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={accountMenuOpen ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              <Person fontSize="small" color="action" />
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={accountMenuOpen}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          style={{
            fontFamily: 'var(--font-mono-regular)',
          }}
          onClick={() => setModalProfileOpen(true)}
        >
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          Perfil
        </MenuItem>

        <MenuItem
          style={{
            fontFamily: 'var(--font-mono-regular)',
          }}
          onClick={() => {
            setModalCompaniesOpen(true)
          }}
        >
          <ListItemIcon>
            <PersonPin fontSize="small" />
          </ListItemIcon>
          {isClient ? 'Empresas' : 'Clientes'}
        </MenuItem>

        <MenuItem
          style={{
            fontFamily: 'var(--font-mono-regular)',
          }}
          onClick={() => setModalDownloadRequestsOpen(true)}
        >
          <ListItemIcon>
            <Download fontSize="small" />
          </ListItemIcon>
          Downloads
        </MenuItem>

        {/* <MenuItem
          style={{
            fontFamily: 'var(--font-mono-regular)',
          }}
          onClick={() => setModalHelpOpen(true)}
        >
          <ListItemIcon>
            <Help fontSize="small" />
          </ListItemIcon>
          Ajuda
        </MenuItem> */}

        <Divider />
        <MenuItem
          onClick={() => logout()}
          style={{
            fontFamily: 'var(--font-mono-regular)',
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>

      <Profile modalProfileOpen={modalProfileOpen} setModalProfileOpen={setModalProfileOpen} />
      <DownloadRequests
        modalDownloadRequestsOpen={modalDownloadRequestsOpen}
        setModalDownloadRequestsOpen={setModalDownloadRequestsOpen}
      />
      <Companies modalCompaniesOpen={modalCompaniesOpen} setModalCompaniesOpen={setModalCompaniesOpen} />
      <HelpModal modalHelpOpen={modalHelpOpen} setModalHelpOpen={setModalHelpOpen} />
    </React.Fragment>
  )
}
